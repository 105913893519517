import type {
  CollectionResult,
  IncludeArr,
  Result,
  TitleListActionBody,
  TitleListCreateBody,
  TitleListEditBody,
  TitleListEntity,
  TitleListEntryEntity,
  TitleListEntryListParams,
  TitleListListParams,
} from "../../types";
import axios from "../../core/axios";
import { assertSuccess, paramsParser } from "../../core";
import { Headers } from "../index";
import { nanoid } from "nanoid";

export class TitleList {
  static async get(
    id: string,
    includes: IncludeArr<TitleListEntity> = [],
    auth?: string,
  ) {
    const resp = await axios.get<Result<TitleListEntity>>(
      `/title/list/${id}` + paramsParser({ includes }),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async find(params: TitleListListParams = {}, auth?: string) {
    const resp = await axios.get<CollectionResult<TitleListEntity>>(
      "/title/list" + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  static async getEntries(
    listId: string,
    params: TitleListEntryListParams = {},
    auth?: string,
  ) {
    const resp = await axios.get<CollectionResult<TitleListEntryEntity>>(
      `/title/list/${listId}/title` + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  static async create(body: TitleListCreateBody, auth: string) {
    const resp = await axios.post<Result<TitleListEntity>>(
      "/title/list/create",
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async update(body: TitleListEditBody, auth: string) {
    const resp = await axios.post<Result<TitleListEntity>>(
      `/title/list/${body.id}`,
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async delete(id: string, version: number, auth: string) {
    const resp = await axios.delete<Result<TitleListEntity>>(
      `/title/list/${id}?version=${version}`,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async modifyTitlesInList(
    listId: string,
    body: TitleListActionBody,
    auth: string,
  ) {
    const resp = await axios.post<Result<TitleListEntity>>(
      `/title/list/${listId}/title`,
      body,
      {
        headers: Headers.Bearer(auth),
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async uploadBanner(
    id: string,
    image: File | Blob,
    version: number,
    auth: string,
  ) {
    const formData = new FormData();
    formData.append(`banner`, image, nanoid());

    const resp = await axios.post<Result<TitleListEntity>>(
      `/title/list/${id}/banner?version=${version}`,
      formData,
      {
        method: "POST",
        headers: {
          ...Headers.Bearer(auth),
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async deleteBanner(id: string, version: number, auth: string) {
    const resp = await axios.delete<Result<TitleListEntity>>(
      `/title/list/${id}/banner?version=${version}`,
      {
        headers: {
          ...Headers.Bearer(auth),
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return assertSuccess(resp.data).data;
  }
}
